.simple_captcha {
  border: 1px solid #ccc;
  padding: 16px !important;
  margin-top: 30px;
  border-radius: 6px;
  width: 100%;
}

.simple_captcha_container {
  display: flex !important;
  justify-content: space-between !important;
}

.simple_captcha .simple_captcha_image {
  border: 1px solid #ccc;
  border-radius: 6px;
}

.simple_captcha .simple_captcha_image img {
  width: 150px !important;
}

.simple_captcha .simple_captcha_field {
  width: 55%;
}

.simple_captcha .simple_captcha_field input {
  width: 100% !important;
  height: 100% !important;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: #e9f0fe;
}
