@import "./application.tailwind.css";
@import "actiontext";
@import "simple_captcha";
@import "trix/dist/trix";

html {
  font-family: Montserrat, sans-serif;
}

input[type="checkbox"] {
  color: #333333;
}

.u-sideBySide {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.embedded-youtube iframe {
  width: 100%;
  max-width: 560px;
  height: 315px;
}

.PageHeader+p {
  margin-top: 0 !important;
}

.preview__img-wrapper {
  --preview: url('../images/sign_up__preview--full.png');

  background: linear-gradient(0deg, #CCCCCC 0%, #F1F1F1 100%);
  background-size: cover;
}

.preview__img {
  background: var(--preview) 35px 45px no-repeat;
  background-size: cover;
}

:root {
  --color-secondary-accent-600: 50 95% 70%;
  /* #fbe268 */
  --color-secondary-success-600: 145 49% 50%;
  /* #42bf76 */
  --color-secondary-success-50: 146 100% 99%;
  /* #f8fffb  */
  --color-secondary-failure-600: 4 94% 69%;
  /* #fa7166 */
  --color-secondary-failure-50: 4 94% 99%;
  /* #fffafa */

  --color-neutral-750: 215, 28%, 17%;
  /* #1F2937 */
  --color-neutral-700: 0 0% 20%;
  /* #333 */
  --color-neutral-650: 0 0% 27%;
  /* #444 */
  --color-neutral-600: 0 0% 33%;
  /* #555 */
  --color-neutral-550: 0 0% 40%;
  /* #666 */
  --color-neutral-300: 0 0% 60%;
  /* #999 */
  --color-neutral-150: 0 0% 85%;
  /* #d9d9d9 */
  --color-neutral-100: 0 0% 96%;
  /* #f4f4f4 */
  --color-neutral-50: 0 0% 98%;
  /* #fafafa */
}

.dnd-item {
  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
}


.sortable-ghost {
  filter: blur(2px);
  opacity: 0.5;

  &:active {
    cursor: grabbing;
  }

  &[draggable=true] {
    cursor: move;
  }
}

.dnd-item--valid {
  background-color: #4ade80;
  /*  "bg-green-400" */
}

.dnd-item--invalid {
  background-color: #f87171;
  /*  bg-red-400" */
}


ul.unordered-list--custom li::marker {
  color: inherit;
  font-size: inherit;
  content: "•";
  font-weight: inherit;
}

.file-item {
  border-color: #666666;
  border-style: solid;
  border-radius: 8px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.file-item--text {}
